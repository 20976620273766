import React from "react";
import { CartContext } from "../context/Cart";
import { formatMoney } from "../utils/utils.js";
import DiscountInput from "./DiscountInput";
import { UserContext } from "../context/User";

const CartList = ({ fixed }) => {
  const { setCart, cart, handleSaveDiscountCode } =
    React.useContext(CartContext);

  const { user } = React.useContext(UserContext);

  const renderCartItems = () => {
    if (cart && cart.items.length > 0) {
      return cart.items.map((item) => {
        const { product } = item;
        console.log("cart", cart);
        return (
          <div className="_cart-item flex my-8 " key={product.id}>
            <div className="_cart-item-img mr-4" style={{ width: 100 }}>
              <img
                src={product.imageUrl ? product.imageUrl : null}
                alt={product.name}
              />
            </div>
            <div className="_cart-item-info">
              <div className="_cart-item-title text-lg font-bold">
                {product.name}
              </div>
              <div className={`_cart-per-price text-sm text-slate-600 `}>
                <span
                  className={`${item.priceAfterDiscount || item.priceAfterDiscount === 0
                      ? "strike-through-product"
                      : ""
                    }`}
                >
                  {formatMoney(product.priceMap || product.priceMsrp)}
                </span>

                {(item.priceAfterDiscount || item.priceAfterDiscount === 0) && (
                  <span className="ml-2">
                    {formatMoney(item.priceAfterDiscount)}
                  </span>
                )}
              </div>

              <div className="_cart-item-price flex items-center mt-3">
                <input
                  disabled
                  type="number"
                  style={{ width: 50 }}
                  className="bg-slate-200 p-2 rounded"
                  value={item.quantity}
                />
                <div className="_cart-item-price ml-4 text-slate-700">
                  {formatMoney(
                    item.priceAfterDiscount !== undefined ||
                      item.priceAfterDiscount === 0
                      ? item.priceAfterDiscount * item.quantity
                      : (product.priceMap || product.priceMsrp) * item.quantity
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const renderSalesTax = () => {
    if (user && user.tax_exempt) {
      return <p>Tax Exempt</p>;
    }
    else if (cart.estimatedTaxes) {
      return <p className="">{formatMoney(cart.estimatedTaxes)}</p>;
    } else {
      return <p>$-</p>;
    }
  };

  const formatDiscountCode = (code) => {
    if (code === "blizzard_rewards") {
      return "Rewards Balance";
    } else {
      return code;
    }
  };

  const renderDiscount = () => {
    if (cart.discountCode) {
      return (
        <div className="flex items-center justify-between bg-slate-200 p-1 rounded mb-2">
          <div className="flex items-center">
            <i
              onClick={() => {
                setCart({ ...cart, discountCode: null });
                handleSaveDiscountCode(null);
              }}
              className="fa-sharp fa-solid mr-2 fa-circle-xmark text-red-500 cursor-pointer"
            ></i>

            <p>{formatDiscountCode(cart.discountCode)}</p>
          </div>
          <p className="text-green-600 font-bold">
            -
            {cart.subtotalAfterDiscount
              ? formatMoney(cart.subtotal - cart.subtotalAfterDiscount)
              : ""}
          </p>
        </div>
      );
    }
  };

  const renderCartInfo = () => {
    return (
      <div className="_cart-subtotal flex justify-between mt-8 border-t border-slate-300 ">
        <div className="text-left w-full pt-4">
          <div className="flex items-center justify-between pb-2">
            <p className="text-lg">Subtotal</p>
            <p className="">{formatMoney(cart.subtotal)}</p>
          </div>

          {renderDiscount()}

          <div className="flex items-center justify-between pb-2">
            <p className="text-lg">Shipping</p>
            <p className=" ml-4">
              {cart.shippingPreference
                ? formatMoney(cart.shippingPreference.amount)
                : "$-"}
            </p>
          </div>

          <div className="flex items-center justify-between pb-4">
            <p className=" text-lg">Sales Tax</p>
            {renderSalesTax()}
          </div>

          <div className="flex items-center justify-between pb-2 pt-4 border-t border-slate-300 w-full">
            <p className=" text-lg font-bold ">Total</p>
            {formatMoney(
              (cart.subtotalAfterDiscount || cart.subtotal) +
              cart.estimatedTaxes +
              (cart.shippingPreference ? cart.shippingPreference.amount : 0)
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${fixed ? "min-h-screen" : null} p-10  relative w-full`}>
      <div className={`${fixed ? "fixed cart-list" : null} `}>
        {renderCartItems()}
        {cart && !cart.discountCode && <DiscountInput />}
        {renderCartInfo()}
      </div>
    </div>
  );
};

export default CartList;

import React, { useState } from "react";
import { countries } from "../utils/countries";
import { count } from "console";

interface CountryOption {
  code: string;
  name: string;
}

interface CountryDropdownProps {
  value: string;
  isRequired?: boolean;
  onChange?: (e: any) => void;
}

const CountryDropdown = (props: CountryDropdownProps) => {
  const { onChange, value, isRequired } = props;
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(
    null
  );

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const country = countries.find((s) => s.code === value);
    setSelectedCountry(country || null);
    onChange(country || null);
  };

  return (
    <div className="relative ">
      <label
        htmlFor="Width"
        className="w-full block text-slate-800 pt-2 text-sm mb-2 text-left"
      >
        Country
      </label>
      <select
        value={value}
        style={{ top: -4, minWidth: 65 }}
        onChange={handleCountryChange}
        className={`relative bg-slate-100 border text-slate-900 text-sm rounded focus:ring-slate-800 focus:border-slate-500 block w-full p-3 ${
          isRequired ? "border-red-500" : "border-slate-500"
        }`}
      >
        <option value="">Select a country</option>
        {countries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CountryDropdown;

import React, { useState } from "react";
import { convertKeysToCamelCase, makeAxiosCall } from "../utils/utils";
import { CartContext } from "../context/Cart";
import RadioGroup from "./RadioGroup";
import RadioSelector from "./RadioSelector";

const AddressValidation = () => {
  const [addresses, setAddresses] = useState([]);
  const [error, setError] = useState();
  const [selectedAddress, setSelectedAddress] = useState(null);

  const { setCart, cart, setHasValidatedAddress, hasValidatedAddress } =
    React.useContext(CartContext);

  const handleRadioGroupSelection = (e) => {
    let shippingAddress = convertKeysToCamelCase(e);

    if (cart.shippingAddress.streetAddressTwo) {
      shippingAddress.streetAddressTwo = cart.shippingAddress.streetAddressTwo;
    }

    setSelectedAddress(e);
    setHasValidatedAddress(true);
    setCart({ ...cart, shippingAddress: shippingAddress });
  };

  React.useEffect(() => {
    if (cart && cart.shippingAddress) {
      makeAxiosCall("post", `/validate-address`, cart.shippingAddress)
        .then((res) => {
          setError(null);
          setAddresses(res.data);
        })
        .catch((err) => {
          console.log(err);
          setError(err.response.data.error);
          setHasValidatedAddress(false);
        });
    }
  }, [cart.shippingAddress]);

  return (
    <div className="">
      {error ? (
        <div className="mt-6">
          <p className="bg-red-200 text-red-600 table p-2 rounded mb-3">
            {error}
          </p>
        </div>
      ) : (
        <RadioGroup
          options={addresses}
          selectedAddress={selectedAddress}
          onChange={(e) => {
            handleRadioGroupSelection(e);
          }}
        />
      )}
    </div>
  );
};

export default AddressValidation;

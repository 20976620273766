import { demoOrder } from "../utils/order_data";
import { convertKeysToCamelCase } from "../utils/utils";
import { ShippingRate } from "./Shipping";

export interface OrderInterface {
  billingAddress: AddressInterface;
  discountAmount: number;
  discountCode: string;
  financialStatus: string;
  fullfilledAt: string;
  fulfillmentStatus: string;
  id: number;
  items: Array<OrderItemInterface>;
  orderNote: string;
  payment: PaymentInterface;
  shippingAddress: AddressInterface;
  soNumber: string;
  subtotal: number;
  paidAt: string;
  paymentId: string;
  paymentMethod: string;
  refundAmount: number;
  shippingPreference: {
    id: number;
    accountNumber?: number;
    shippingCarrier: { id: number; name: string };
    shippingService: { id: number; name: string };
  };
  shipping: number;
  taxDetails: Array<{
    name: string;
    value: number;
  }>;
  taxes: number;
  total: number;
  updatedAt: string;
  createdAt: string;
  anonymousCheckoutEmail?: string;
  customer: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
}

export interface OrderPayloadInterface {
  contactFirstName?: string;
  contactLastName?: string;
  cartId: number;
  email: string;
  orderNote: string;
  shippingPreference: ShippingPreferenceInterface;
  payment: PaymentInterface;
  shippingAddress: AddressInterface;
  taxExempt: boolean;
}

export interface ShippingPreferenceInterface {
  amount: number;
  carrierId: number;
  serviceId: number;
  name: string;
}

export interface AddressInterface {
  id?: string;
  addressee: string;
  streetAddress: string;
  streetAddressTwo: string;
  city: string;
  zip: string;
  state: string;
  country: string;
}

interface PaymentInterface {
  billingAddress: AddressInterface;
  paymentProfileId?: number;
  card: {
    cardNumber: string;
    expirationDate: string;
    cvv: string;
  };
}

interface OrderItemInterface {
  fulfillmentStatus: string;
  id: number;
  orderId: number;
  price: number;
  product: {
    id: number;
    imageUrl: string;
    name: string;
  };
  quantity: number;
  productSku?: string;
  productName: string;
}

interface ItemInterface {
  id: number;
  quantity: number;
  priceAfterDiscount?: number;
  product: {
    id: number;
    imageUrl: string;
    name: string;
    priceMap?: number;
    priceMsrp?: number;
    price?: number;
    producttype?: string;
  };
}

export interface CartInterface {
  id: number;
  taxExemptType: string;
  taxExempt: boolean;
  contactFirstName?: string;
  contactLastName?: string;
  shipType: string;
  annoymouseUserId?: number;
  userId?: number;
  items: Array<ItemInterface>;
  email?: string;
  phone?: string;
  orderNote?: string;
  subtotal?: number;
  estimatedTaxes?: number;
  total?: number;
  discountValue?: number;
  discountType?: string;
  subtotalAfterDiscount?: number;
  discountCode?: string;
  deliveryMethod?: string;
  billingAddress?: AddressInterface;
  shippingAddress?: AddressInterface;
  shippingPreference: ShippingPreferenceInterface;
  payment?: PaymentInterface;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export const INIT_SHIPPING_ADDRESS: AddressInterface = {
  addressee: "",
  streetAddress: "",
  streetAddressTwo: "",
  city: "",
  zip: "",
  country: "US",
  state: "",
};

export const INIT_PAYMENT: PaymentInterface = {
  paymentProfileId: null,
  card: {
    cardNumber: null,
    expirationDate: "",
    cvv: null,
  },
  billingAddress: INIT_SHIPPING_ADDRESS,
};

const INIT_SHIPPING_PREFERENCE: ShippingPreferenceInterface = {
  amount: 0,
  carrierId: 0,
  serviceId: 0,
  name: "",
};

export const INIT_CART: CartInterface = {
  id: 0,
  taxExemptType: "Non-exempt",
  taxExempt: false,
  contactFirstName: "",
  contactLastName: "",
  shipType: "ship",
  phone: "",
  email: "",
  deliveryMethod: "",
  billingAddress: INIT_SHIPPING_ADDRESS,
  shippingAddress: INIT_SHIPPING_ADDRESS,
  shippingPreference: INIT_SHIPPING_PREFERENCE,
  payment: INIT_PAYMENT,
  items: [],
  subtotal: 0,
  estimatedTaxes: 0,
  total: 0,
};

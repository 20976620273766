export interface TaxInfo {
  state: string;
  abbreviation: string;
  stateTaxRate: number;
  stateTaxRank: number;
  localTaxRate: number;
  combinedTaxRate: number;
  combinedRank: number;
}

export const tax = [
  {
    state: "Alabama",
    abbreviation: "AL",
    stateTaxRate: 0.04,
    stateTaxRank: 40,
    localTaxRate: 0.051,
    combinedTaxRate: 0.091,
    combinedRank: 5,
  },
  {
    state: "Alaska",
    abbreviation: "AK",
    stateTaxRate: 0,
    stateTaxRank: 46,
    localTaxRate: 0.0176,
    combinedTaxRate: 0.0176,
    combinedRank: 46,
  },
  {
    state: "Arizona",
    abbreviation: "AZ",
    stateTaxRate: 0.056,
    stateTaxRank: 28,
    localTaxRate: 0.0273,
    combinedTaxRate: 0.0833,
    combinedRank: 11,
  },
  {
    state: "Arkansas",
    abbreviation: "AR",
    stateTaxRate: 0.065,
    stateTaxRank: 9,
    localTaxRate: 0.0291,
    combinedTaxRate: 0.0941,
    combinedRank: 3,
  },
  {
    state: "California",
    abbreviation: "CA",
    stateTaxRate: 0.0725,
    stateTaxRank: 1,
    localTaxRate: 0.0129,
    combinedTaxRate: 0.0854,
    combinedRank: 9,
  },
  {
    state: "Colorado",
    abbreviation: "CO",
    stateTaxRate: 0.029,
    stateTaxRank: 45,
    localTaxRate: 0.0462,
    combinedTaxRate: 0.0752,
    combinedRank: 16,
  },
  {
    state: "Connecticut",
    abbreviation: "CT",
    stateTaxRate: 0.0635,
    stateTaxRank: 12,
    localTaxRate: 0,
    combinedTaxRate: 0.0635,
    combinedRank: 33,
  },
  {
    state: "Delaware",
    abbreviation: "DE",
    stateTaxRate: 0,
    stateTaxRank: 46,
    localTaxRate: 0,
    combinedTaxRate: 0,
    combinedRank: 47,
  },
  {
    state: "Florida",
    abbreviation: "FL",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0.008,
    combinedTaxRate: 0.068,
    combinedRank: 28,
  },
  {
    state: "Georgia",
    abbreviation: "GA",
    stateTaxRate: 0.04,
    stateTaxRank: 40,
    localTaxRate: 0.0315,
    combinedTaxRate: 0.0715,
    combinedRank: 20,
  },
  {
    state: "Hawaii",
    abbreviation: "HI",
    stateTaxRate: 0.04,
    stateTaxRank: 40,
    localTaxRate: 0.0035,
    combinedTaxRate: 0.0435,
    combinedRank: 45,
  },
  {
    state: "Idaho",
    abbreviation: "ID",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0.0003,
    combinedTaxRate: 0.0603,
    combinedRank: 37,
  },
  {
    state: "Illinois",
    abbreviation: "IL",
    stateTaxRate: 0.0625,
    stateTaxRank: 13,
    localTaxRate: 0.0245,
    combinedTaxRate: 0.087,
    combinedRank: 7,
  },
  {
    state: "Indiana",
    abbreviation: "IN",
    stateTaxRate: 0.07,
    stateTaxRank: 2,
    localTaxRate: 0,
    combinedTaxRate: 0.07,
    combinedRank: 22,
  },
  {
    state: "Iowa",
    abbreviation: "IA",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0.008,
    combinedTaxRate: 0.068,
    combinedRank: 27,
  },
  {
    state: "Kansas",
    abbreviation: "KS",
    stateTaxRate: 0.065,
    stateTaxRank: 9,
    localTaxRate: 0.0218,
    combinedTaxRate: 0.0868,
    combinedRank: 8,
  },
  {
    state: "Kentucky",
    abbreviation: "KY",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0,
    combinedTaxRate: 0.06,
    combinedRank: 38,
  },
  {
    state: "Louisiana",
    abbreviation: "LA",
    stateTaxRate: 0.05,
    stateTaxRank: 33,
    localTaxRate: 0.0502,
    combinedTaxRate: 0.1002,
    combinedRank: 1,
  },
  {
    state: "Maine",
    abbreviation: "ME",
    stateTaxRate: 0.055,
    stateTaxRank: 29,
    localTaxRate: 0,
    combinedTaxRate: 0.055,
    combinedRank: 42,
  },
  {
    state: "Maryland",
    abbreviation: "MD",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0,
    combinedTaxRate: 0.06,
    combinedRank: 38,
  },
  {
    state: "Massachusetts",
    abbreviation: "MA",
    stateTaxRate: 0.0625,
    stateTaxRank: 13,
    localTaxRate: 0,
    combinedTaxRate: 0.0625,
    combinedRank: 35,
  },
  {
    state: "Michigan",
    abbreviation: "MI",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0,
    combinedTaxRate: 0.06,
    combinedRank: 38,
  },
  {
    state: "Minnesota",
    abbreviation: "MN",
    stateTaxRate: 0.06875,
    stateTaxRank: 6,
    localTaxRate: 0.0055,
    combinedTaxRate: 0.0742,
    combinedRank: 17,
  },
  {
    state: "Mississippi",
    abbreviation: "MS",
    stateTaxRate: 0.07,
    stateTaxRank: 2,
    localTaxRate: 0.0007,
    combinedTaxRate: 0.0707,
    combinedRank: 21,
  },
  {
    state: "Missouri",
    abbreviation: "MO",
    stateTaxRate: 0.04225,
    stateTaxRank: 39,
    localTaxRate: 0.038,
    combinedTaxRate: 0.0803,
    combinedRank: 14,
  },
  {
    state: "Montana",
    abbreviation: "MT",
    stateTaxRate: 0,
    stateTaxRank: 46,
    localTaxRate: 0,
    combinedTaxRate: 0,
    combinedRank: 47,
  },
  {
    state: "Nebraska",
    abbreviation: "NE",
    stateTaxRate: 0.055,
    stateTaxRank: 29,
    localTaxRate: 0.0139,
    combinedTaxRate: 0.0689,
    combinedRank: 25,
  },
  {
    state: "Nevada",
    abbreviation: "NV",
    stateTaxRate: 0.0685,
    stateTaxRank: 7,
    localTaxRate: 0.0129,
    combinedTaxRate: 0.0814,
    combinedRank: 13,
  },
  {
    state: "New Hampshire",
    abbreviation: "NH",
    stateTaxRate: 0,
    stateTaxRank: 46,
    localTaxRate: 0,
    combinedTaxRate: 0,
    combinedRank: 47,
  },
  {
    state: "New Jersey",
    abbreviation: "NJ",
    stateTaxRate: 0.06625,
    stateTaxRank: 8,
    localTaxRate: -0.0003,
    combinedTaxRate: 0.066,
    combinedRank: 30,
  },
  {
    state: "New Mexico",
    abbreviation: "NM",
    stateTaxRate: 0.05125,
    stateTaxRank: 32,
    localTaxRate: 0.0254,
    combinedTaxRate: 0.0766,
    combinedRank: 15,
  },
  {
    state: "New York",
    abbreviation: "NY",
    stateTaxRate: 0.04,
    stateTaxRank: 40,
    localTaxRate: 0.0449,
    combinedTaxRate: 0.0849,
    combinedRank: 10,
  },
  {
    state: "North Carolina",
    abbreviation: "NC",
    stateTaxRate: 0.0475,
    stateTaxRank: 36,
    localTaxRate: 0.022,
    combinedTaxRate: 0.0695,
    combinedRank: 24,
  },
  {
    state: "North Dakota",
    abbreviation: "ND",
    stateTaxRate: 0.05,
    stateTaxRank: 33,
    localTaxRate: 0.018,
    combinedTaxRate: 0.068,
    combinedRank: 26,
  },
  {
    state: "Ohio",
    abbreviation: "OH",
    stateTaxRate: 0.0575,
    stateTaxRank: 27,
    localTaxRate: 0.014,
    combinedTaxRate: 0.0715,
    combinedRank: 19,
  },
  {
    state: "Oklahoma",
    abbreviation: "OK",
    stateTaxRate: 0.045,
    stateTaxRank: 37,
    localTaxRate: 0.0441,
    combinedTaxRate: 0.0891,
    combinedRank: 6,
  },
  {
    state: "Oregon",
    abbreviation: "OR",
    stateTaxRate: 0,
    stateTaxRank: 46,
    localTaxRate: 0,
    combinedTaxRate: 0,
    combinedRank: 47,
  },
  {
    state: "Pennsylvania",
    abbreviation: "PA",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0.0034,
    combinedTaxRate: 0.0634,
    combinedRank: 34,
  },
  {
    state: "Rhode Island",
    abbreviation: "RI",
    stateTaxRate: 0.07,
    stateTaxRank: 2,
    localTaxRate: 0,
    combinedTaxRate: 0.07,
    combinedRank: 22,
  },
  {
    state: "South Carolina",
    abbreviation: "SC",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0.0137,
    combinedTaxRate: 0.0737,
    combinedRank: 18,
  },
  {
    state: "South Dakota",
    abbreviation: "SD",
    stateTaxRate: 0.045,
    stateTaxRank: 37,
    localTaxRate: 0.019,
    combinedTaxRate: 0.064,
    combinedRank: 31,
  },
  {
    state: "Tennessee",
    abbreviation: "TN",
    stateTaxRate: 0.07,
    stateTaxRank: 2,
    localTaxRate: 0.0246,
    combinedTaxRate: 0.0946,
    combinedRank: 2,
  },
  {
    state: "Texas",
    abbreviation: "TX",
    stateTaxRate: 0.0625,
    stateTaxRank: 13,
    localTaxRate: 0.0192,
    combinedTaxRate: 0.0817,
    combinedRank: 12,
  },
  {
    state: "Utah",
    abbreviation: "UT",
    stateTaxRate: 0.0595,
    stateTaxRank: 26,
    localTaxRate: 0.0082,
    combinedTaxRate: 0.0677,
    combinedRank: 29,
  },
  {
    state: "Vermont",
    abbreviation: "VT",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0.0018,
    combinedTaxRate: 0.0618,
    combinedRank: 36,
  },
  {
    state: "Virginia",
    abbreviation: "VA",
    stateTaxRate: 0.053,
    stateTaxRank: 31,
    localTaxRate: 0.0033,
    combinedTaxRate: 0.0563,
    combinedRank: 41,
  },
  {
    state: "Washington",
    abbreviation: "WA",
    stateTaxRate: 0.065,
    stateTaxRank: 9,
    localTaxRate: 0.0268,
    combinedTaxRate: 0.0918,
    combinedRank: 4,
  },
  {
    state: "West Virginia",
    abbreviation: "WV",
    stateTaxRate: 0.06,
    stateTaxRank: 16,
    localTaxRate: 0.0037,
    combinedTaxRate: 0.0637,
    combinedRank: 32,
  },
  {
    state: "Wisconsin",
    abbreviation: "WI",
    stateTaxRate: 0.05,
    stateTaxRank: 33,
    localTaxRate: 0.0042,
    combinedTaxRate: 0.0542,
    combinedRank: 44,
  },
  {
    state: "Wyoming",
    abbreviation: "WY",
    stateTaxRate: 0.04,
    stateTaxRank: 40,
    localTaxRate: 0.0146,
    combinedTaxRate: 0.0546,
    combinedRank: 43,
  },
  {
    state: "D.C.",
    abbreviation: "DC",
    stateTaxRate: 0.0575,
    stateTaxRank: -27,
    localTaxRate: 0,
    combinedTaxRate: 0.0575,
    combinedRank: -41,
  },
];

import React, { useState } from "react";
import { CartContext } from "../context/Cart";
import logo from "../images/blizzard_logo.png";
import OrderList from "./OrderList";
import {
  convertKeysToCamelCase,
  formatMoney,
  formatTimeStampToLocale,
  makeAxiosCall,
} from "../utils/utils";
import Accordion from "./Accordion";

const OrderDetails = () => {
  const { order, setOrder } = React.useContext(CartContext);
  const [error, setError] = useState();

  React.useEffect(() => {
    if (!order.id) {
      //fetch order details
      const id = window.location.pathname.split("/")[2];
      makeAxiosCall("get", `/web-order/${id}`)
        .then((res) => {
          setOrder(convertKeysToCamelCase(res.data));
          console.log(res.data);
        })
        .catch((err) => {
          setError(err.response.data);
          console.log(err);
        });
    }
  }, []);

  const renderStatuses = () => {
    return (
      <div className="flex border-b-2 border-dotted border-slate-500 pb-3">
        <div>
          <div className="mb-3">
            Created At
            <span className="py-1 px-2 ml-3 bg-slate-200 rounded">
              {formatTimeStampToLocale(order.createdAt)}
            </span>
          </div>
          <div className="mb-3">
            Last Updated
            <span className="py-1 px-2 ml-3 bg-slate-200 rounded">
              {formatTimeStampToLocale(order.updatedAt)}
            </span>
          </div>
          <div className="mb-3">
            Financial Status
            <span className="py-1 px-2 ml-3 bg-slate-200 rounded">
              {order.financialStatus.toUpperCase()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderAddress = (address) => {
    return (
      <div className="flex flex-col">
        <span className="font-bold">{address.addressee}</span>
        <span>{address.streetAddress}</span>
        <span>{address.streetAddressTwo}</span>
        <span>
          {address.city}, {address.state} {address.zip}
        </span>
      </div>
    );
  };

  if (order && order.id) {
    return (
      <div className="h-full">
        <div className="flex justify-end">
          <div className="cart-main w-2/3 flex justify-end">
            <div className="cart-form w-full justify-center px-4 lg:w-4/5 lg:px-0 lg:flex-end lg:mr-14">
              <div
                onClick={() => {
                  window.location.href = "https://www.blizzardpro.com";
                }}
              >
                <img
                  alt="Blizzard Logo"
                  className="m-auto lg:m-inherit"
                  src={logo}
                  width={300}
                />
              </div>

              <div className="pb-6">
                <p className="text-lg text-slate-800 mb-3">Order #{order.id}</p>
                <p className="text-3xl  font-bold">
                  <i className="text-green-500 mr-2 fa-solid fa-badge-check"></i>
                  Thank you!
                </p>
                <p className="mb-10 text-slate-700 pt-2 text-lg">
                  A receipt has been sent to the order email for your records.
                  <br />
                  Please{" "}
                  <a
                    className="underline text-primary"
                    href="mailto:support@blizzardpro.com"
                  >
                    contact support
                  </a>{" "}
                  if you have any further questions.
                </p>
                {order.customer && (
                  <div className="mb-3">
                    Customer
                    <span className="py-1 px-2 ml-3 bg-slate-200 rounded">
                      {order.customer.email}
                    </span>
                  </div>
                )}

                <div className="cart-accordion md:hidden">
                  <Accordion
                    title={
                      <div className="flex justify-between w-full">
                        <p className="text-slate-600">View Cart Details</p>
                        <p className="font-bold ">{formatMoney(order.total)}</p>
                      </div>
                    }
                  >
                    <OrderList fixed={false} />
                  </Accordion>
                </div>

                {renderStatuses()}
                <div className="flex justify-between mt-4">
                  <div className="w-1/2 mr-2">
                    <div className="text-xl py-3">
                      <i className="text-slate-500 fa-sharp fa-solid fa-location-dot mr-2"></i>{" "}
                      <span className="text-slate-800">Shipping Address</span>
                    </div>
                    {renderAddress(order.shippingAddress)}
                    <div className="text-xl pt-6">
                      <i className="text-slate-500 fa-sharp fa-solid fa-box mr-2"></i>
                      <span className="text-slate-800">Shipping Method</span>
                    </div>
                    <div className=" my-3">
                      <span className="">
                        {`${order.shippingPreference
                            ? order.shippingPreference.shippingCarrier.name
                            : "None"
                          } ${order.shippingPreference
                            ? order.shippingPreference.shippingService.name
                            : ""
                          } - (${order.shipping > 0
                            ? formatMoney(order.shipping)
                            : "No data"
                          })`}
                      </span>
                      <span></span>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="text-xl py-3">
                      <i className="text-slate-500 fa-sharp fa-solid fa-credit-card mr-2"></i>
                      <span className="text-slate-800">Billing</span>
                    </div>
                    {renderAddress(order.shippingAddress)}
                  </div>
                </div>
                <div className="mt-6">
                  <a
                    className="bg-primary rounded-lg p-3 inline-block mt-4 text-white text-lg"
                    href="https://www.blizzardpro.com"
                  >
                    Browse More Products ›
                  </a>
                </div>
              </div>
              {order && order.items.length > 0 ? (
                <div></div>
              ) : (
                <div>
                  <h2 className="text-lg">This order does not exist.</h2>
                  <a
                    className="bg-primary rounded-lg p-3 inline-block mt-4 text-white text-lg"
                    href="https://www.blizzardpro.com"
                  >
                    Browse Products ›
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className="cart-list bg-slate-50 w-1/3">
            <OrderList fixed />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-wrap h-screen items-center text-center justify-center">
        <div>
          <p className="w-full font-bold text-3xl">
            {error ? error : "Order not found"}
          </p>
          {error === "Unauthorized" ? (
            <a
              href={`https://blizzardpro.com/account?redirect=${window.location.href}`}
              className="block bg-primary rounded-full text-white px-4 py-3 mt-4"
            >
              Sign In
            </a>
          ) : (
            <a
              href="https://www.blizardpro.com"
              className="block bg-primary rounded-full text-white px-4 py-3 mt-4"
            >
              Browse Products
            </a>
          )}
        </div>
      </div>
    );
  }
};

export default OrderDetails;

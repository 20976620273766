import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import UserProvider from "./context/User";
import Cart from "./components/Cart";
import CartProvider from "./context/Cart";
import OrderDetails from "./components/OrderDetails";

function App() {
  return (
    <Router>
      <div className="App bg-white min-h-screen pb-24">
        <UserProvider>
          <CartProvider>
            <Routes>
              <Route path="/" element={<Cart />} />
              <Route path="/order/:id" element={<OrderDetails />} />
            </Routes>
          </CartProvider>
        </UserProvider>
      </div>
    </Router>
  );
}

export default App;

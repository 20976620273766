import axios from "axios";

export const API_URL = "https://api.blizzardpro.com";
export const DEV_API_URL = "https://api.dev3.io";

export const makeAxiosCall = (method, endpoint, data, accessToken, config = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Include the Authorization header with the Bearer token
      const headers = {
        ...config.headers, // Spread any existing headers
        Authorization: `Bearer ${accessToken}`, // Add the Authorization header
      };

      const response = await axios({
        method: method,
        url: API_URL + endpoint,
        data: data,
        timeout: 10000,
        withCredentials: true,
        headers: headers, // Use the headers including the Authorization
        ...config,
      });

      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};


export const states = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const formatMoney = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const calculateSubtotal = (items) => {
  let subtotal = 0;
  items.forEach((item) => {
    subtotal +=
      (item.product.price_map || item.product.price_msrp) * item.quantity;
  });
  return subtotal;
};
export const formatTimeStampToLocale = (timestamp) => {
  if (typeof timestamp === "number") {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDateTime = new Date(timestamp).toLocaleString("en-US", {
      timeZone: tz,
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

    return localDateTime;
  } else if (typeof timestamp === "string" && timestamp !== "UPS Ground") {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(timestamp);
    const localDateTime = date.toLocaleString("en-US", {
      timeZone: tz,
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: undefined, // Exclude seconds from the formatting
    });

    return localDateTime;
  } else {
    return timestamp;
  }
};

export const convertKeysToCamelCase = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item));
  }

  const result = {};

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = key
        .replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
        .replace("_", "");
      result[camelCaseKey] = convertKeysToCamelCase(obj[key]);
    }
  }

  return result;
};

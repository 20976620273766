import React, { useState } from "react";
import logo from "../images/blizzard_logo.png";
import Input from "./Input";
import { CartContext } from "../context/Cart";
import AddressForm from "./AddressForm";
import CartList from "./CartList";
import ShippingRates from "./ShippingRates";
import CartOverview from "./CartOverview";
import PaymentForm from "./PaymentForm";
import Accordion from "./Accordion";

import {
  convertKeysToCamelCase,
  formatMoney,
  makeAxiosCall,
} from "../utils/utils.js";
import { UserContext } from "../context/User";
import { OrderPayloadInterface } from "../interfaces/Cart";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "./LoadingOverlay";

import ReactGA from "react-ga4";
import TaxExemptDropdown from "./TaxExemptDropdown";
import { tax } from "../utils/sales_tax";
import HubspotContactForm from "./HubspotForm";

ReactGA.initialize("G-CLBLZ3LTXX");

const Cart = () => {
  const navigate = useNavigate();

  const { cart, setOrder, setCart, saveCart, hasValidatedAddress } =
    React.useContext(CartContext);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [error, setError] = useState(null);
  const [taxExemptShown, setTaxExemptShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = React.useContext(UserContext);

  const tabs = [{ title: "Info" }, { title: "Shipping" }, { title: "Payment" }];

  React.useEffect(() => {
    if (cart && cart.taxExempt) {
      setTaxExemptShown(cart.taxExempt);
    }
  }, [cart]);


  const submitOrder = async () => {
    const orderObj: OrderPayloadInterface = {
      contactFirstName: cart.contactFirstName ? cart.contactFirstName : "",
      contactLastName: cart.contactLastName ? cart.contactLastName : "",
      cartId: cart.id,
      orderNote: cart.orderNote ? cart.orderNote : "",
      email: cart.email,
      shippingPreference: cart.shippingPreference,
      payment: cart.payment,
      shippingAddress: cart.shippingAddress,
      taxExempt: cart.taxExempt
    };

    setLoading(true);

    makeAxiosCall("post", "/submit-web-order", orderObj)
      .then((res) => {
        setOrder(convertKeysToCamelCase(res.data));

        ReactGA.gtag("event", "purchase", {
          currency: "USD",
          transaction_id: res.data.id,
          value: res.data.total,
          shipping: res.data.shipping,
          tax: res.data.taxes,
          coupon: res.data.discount_code,
          items: formatCartItemsForGA(),
        });

        setLoading(false);
        navigate(`/order/${res.data.id}?msg=success`); // Navigate to the "/success" page after setting the order
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.error);
      });
  };

  const renderCheckoutNav = () => {
    return (
      <div className="flex items-center flex-wrap">
        {tabIndex === 2 ? (
          <div className="w-full">
            <CartOverview tabIndex={tabIndex} setTabIndex={setTabIndex} />
          </div>
        ) : null}
      </div>
    );
  };

  const handleDeliveryMethod = (type) => {
    let shippingAddress = cart.shippingAddress;

    if (type === "pickup") {
      cart.shipType = "pickup";
      setCart({
        ...cart,
        shippingAddress: shippingAddress,
        shippingPreference: {
          name: "Pickup",
          amount: 0,
          carrierId: 8,
          serviceId: 27,
        },
      });
    } else {
      cart.shipType = "ship";
      setCart({
        ...cart,
        shippingAddress: shippingAddress,
      });
    }
  };

  const formatCartItemsForGA = () => {
    const cartItems = cart.items.map((item) => {
      return {
        item_id: item.product.id,
        item_name: item.product.name,
        price: item.product.priceMap || item.product.priceMsrp,
        quantity: item.quantity,
      };
    });

    return cartItems;
  };

  const handleShippingInfoGA = () => {
    ReactGA.gtag("event", "add_shipping_info", {
      currency: "USD",
      value: cart.subtotal,
      shipping_tier: cart.shippingPreference.name,
      items: formatCartItemsForGA(),
    });
  };

  const handlePaymentInfoGA = () => {
    ReactGA.gtag("event", "add_payment_info", {
      currency: "USD",
      value: cart.subtotal,
      payment_type: "Credit Card",
      items: formatCartItemsForGA(),
    });
  };

  const validateStep = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        if (
          cart.email &&
          ((cart.shippingAddress.streetAddress && hasValidatedAddress) ||
            cart.shipType === "pickup")
        ) {
          saveCart();
          return true;
        } else {
          return false;
        }
      case 1:
        if (cart.shippingPreference && cart.shippingPreference.name !== "") {
          handleShippingInfoGA();
          return true;
        } else {
          return false;
        }
      case 2:
        if (
          cart.payment &&
          cart.payment.card &&
          cart.payment.billingAddress.streetAddress !== "" &&
          cart.payment.billingAddress.city !== "" &&
          cart.payment.billingAddress.state !== "" &&
          cart.payment.billingAddress.zip !== ""
        ) {
          handlePaymentInfoGA();
          return true;
        } else {
          return false;
        }

      default:
        return true;
    }
  };

  const renderTaxExempt = () => {
    if (!taxExemptShown) return;

    if (user && user.email) {
      return <TaxExemptDropdown value={cart.taxExemptType} />;
    }
  };

  const renderTab = () => {
    switch (tabIndex) {
      case 0:
        return (
          <div>
            <h3 className="text-2xl font-bold">Contact</h3>
            <p className="pb-4">
              We'll use this info to keep you updated on your order.
            </p>

            <div className="grid grid-cols-2">
              <div className="mr-2">
                <Input
                  label="First Name"
                  type="text"
                  id="firstName"
                  placeholder="First name"
                  value={cart.contactFirstName}
                  onChange={(e) => setCart({ ...cart, contactFirstName: e })}
                />
              </div>
              <div>
                <Input
                  label="Last Name"
                  type="text"
                  id="lastName"
                  placeholder="Last name"
                  value={cart.contactLastName}
                  onChange={(e) => setCart({ ...cart, contactLastName: e })}
                />
              </div>
            </div>

            <Input
              label="Email Address* (required)"
              type="email"
              id="email"
              placeholder="Email address"
              value={cart.email}
              isRequired={error && !cart.email}
              onChange={(e) => setCart({ ...cart, email: e })}
            />

            <Input
              label="Phone (optional)"
              type="tel"
              id="tel"
              placeholder="Phone Number"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              value={cart.phone}
              onChange={(e) => setCart({ ...cart, phone: e })}
            />

            <div className="flex items-center pl-4 border bg-slate-50 border-slate-400 rounded-t">
              <input
                checked={cart.shipType === "ship" ? true : false}
                id="bordered-radio-1"
                type="radio"
                onChange={() => handleDeliveryMethod("ship")}
                value=""
                name="bordered-radio"
                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-primary   focus:ring-2 "
              />
              <label
                htmlFor="bordered-radio-1"
                className="w-full py-4 ml-2 text-sm font-medium text-gray-900 "
              >
                Ship
              </label>
            </div>

            <div className="flex items-center pl-4 border-b border-x bg-slate-50 border-slate-400 rounded-b">
              <input
                checked={cart.shipType === "pickup"}
                onChange={() => handleDeliveryMethod("pickup")}
                id="bordered-radio-2"
                type="radio"
                value=""
                name="bordered-radio"
                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-primary   focus:ring-2"
              />
              <label
                htmlFor="bordered-radio-2"
                className="w-full py-4 ml-2 text-sm font-medium text-gray-900 "
              >
                Pickup
              </label>
            </div>

            {cart.shipType === "pickup" && (
              <>
                <Input
                  label="Pickup Name* (required)"
                  type="text"
                  id="name"
                  placeholder="Who is picking up the order?"
                  value={cart.shippingAddress.addressee}
                  isRequired={error && !cart.shippingAddress.addressee}
                  onChange={(e) =>
                    setCart({
                      ...cart,
                      shippingAddress: {
                        ...cart.shippingAddress,
                        addressee: e,
                      },
                    })
                  }
                />
                <p className="mt-4 pb-4">
                  What time are you coming?{" "}
                  <span className="text-slate-600">
                    (Business Hours: 8am - 4pm)
                  </span>
                </p>
                <textarea
                  className="w-full border border-slate-400 rounded p-2 text-sm"
                  placeholder="Order notes"
                  style={{ minHeight: 100 }}
                  value={cart.orderNote}
                  onChange={(e) =>
                    setCart({ ...cart, orderNote: e.target.value })
                  }
                />
              </>
            )}

            <div className="flex items-center mt-6">
              <h3 className="text-xl font-bold ">Tax Exempt?</h3>
              <input
                className="ml-3 h-4 w-4 text-primary focus:ring-primary   focus:ring-2"
                type="checkbox"
                disabled={!user || (user && !user.tax_exempt)}
                onClick={() => setCart({ ...cart, taxExempt: !cart.taxExempt })}
                checked={cart.taxExempt}
              />
            </div>

            {!user && (
              <div>
                <p className="text-sm text-slate-600">
                  You need to Sign Up/In and have your tax exempt forms
                  submitted to be tax exempt.
                </p>
                <a
                  className="text-sm underline text-primary"
                  href={`https://blizzardpro.com/account?redirect=${window.location.href}`}
                >
                  Sign In / Sign Up ›
                </a>
              </div>
            )}
            {user && !user.tax_exempt && (
              <div>
                <button
                  onClick={() =>
                  (window.location.href = `https://www.blizzardpro.com/tax-exempt${user.email ? "?email=" + user.email : ""
                    }`)
                  }
                  className="mt-6 border border-primary text-slate-800 rounded p-2 hover:bg-primary hover:text-white"
                >
                  Apply for Exemption
                </button>
              </div>
            )}
            {/* <p className="pb-4">
              We'll use this to keep you updated on your order.
            </p> */}

            {renderTaxExempt()}

            {cart.shipType === "ship" && (
              <div className="mt-8">
                <AddressForm
                  validate={validateShippingAddress()}
                  isRequired={
                    error &&
                    !cart.shippingAddress.streetAddress &&
                    hasValidatedAddress
                  }
                  keyName="shippingAddress"
                  data={cart}
                  title="Shipping Address"
                />
              </div>
            )}
          </div>
        );
      case 1:
        return (
          <div>
            <ShippingRates />
          </div>
        );
      case 2:
        return (
          <div>
            <PaymentForm />
            <AddressForm
              isRequired={
                error && !cart.payment.billingAddress && hasValidatedAddress
              }
              keyName="billingAddress"
              data={cart}
              title="Billing Address"
            />
          </div>
        );
      default:
        return;
    }
  };

  const renderStepName = (index) => {
    const prefix = "Continue to ";
    switch (index) {
      case 0:
        return prefix + "Info";
      case 1:
        return prefix + "Shipping";
      case 2:
        return prefix + "Payment";
      default:
        return "Submit Order";
    }
  };

  const renderUserNav = () => {
    if (user) {
      return (
        <p className="bg-slate-100 p-2 rounded table mb-4">
          Logged in as
          <span className="pl-1 font-bold">
            {user.email ? user.email : "annoymous"}
          </span>
        </p>
      );
    } else {
      const url = window.location.href;
      return (
        <div className="pb-3">
          Already have an account?
          <a
            className="pl-1 underline text-primary"
            href={`https://www.blizzardpro.com/account?redirect=${url}`}
          >
            Sign In
          </a>
        </div>
      );
    }
  };

  const validateShippingAddress = () => {
    const { shippingAddress } = cart;
    const { streetAddress, city, state, zip, country, addressee } =
      shippingAddress;
    if (streetAddress && city && state && zip && country && addressee) {
      return true;
    } else {
      return false;
    }
  };

  const handleNextStepButton = async () => {
    const isValidated = await validateStep(tabIndex);
    if (isValidated) {
      if (tabIndex === 0 && cart.shipType === "pickup") {
        setTabIndex(2);
        return;
      }

      if (tabIndex === 2) {
        submitOrder();
        return;
      }

      setError(null);
      setTabIndex(tabIndex + 1);
    } else {
      setError("Please fill out all required fields");
    }
  };

  return (
    <div className="h-full">
      <div className="flex justify-end">
        <div className="cart-main xl:w-2/3 flex justify-end">
          <div className="cart-form w-full justify-center px-4 lg:w-4/5 lg:px-0 lg:flex-end lg:mr-14">
            <img
              alt="Blizzard Logo"
              className="m-auto lg:m-inherit"
              src={logo}
              width={300}
            />
            {renderUserNav()}

            {renderCheckoutNav()}
            <div className="cart-accordion md:hidden">
              <Accordion
                title={
                  <div className="flex justify-between w-full">
                    <p className="text-slate-600">View Cart Details</p>
                    <p className="font-bold ">{formatMoney(cart.subtotal)}</p>
                  </div>
                }
              >
                <CartList fixed={false} />
              </Accordion>
            </div>
            <div className="pb-6"></div>
            {cart && cart.items.length > 0 ? (
              <div>
                {renderTab()}
                <button
                  disabled={loading}
                  onClick={() => handleNextStepButton()}
                  className="bg-primary rounded-lg p-3 inline-block mt-4 text-white text-lg"
                >
                  {renderStepName(tabIndex + 1)} ›
                </button>
                {error && (
                  <p className="bg-red-200 text-red-700 table p-2 mt-2 rounded">
                    {error}
                  </p>
                )}
              </div>
            ) : (
              <div>
                <h2 className="text-lg">
                  You currently have no items to checkout
                </h2>
                <a
                  className="bg-primary rounded-lg p-3 inline-block mt-4 text-white text-lg"
                  href="https://www.blizzardpro.com"
                >
                  Browse Products ›
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="cart-list bg-slate-50 w-full xl:w-2/3 2xl:w-1/3">
          <CartList fixed />
        </div>
      </div>
      <LoadingOverlay loading={loading} />
    </div>
  );
};

export default Cart;

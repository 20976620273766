import React, { useState } from "react";

const LoadingOverlay = ({ loading }) => {
  return (
    <div
      className={`w-full h-full fixed left-0 top-0 bg-white pointer-events-none	 ${
        loading ? "opacity-80" : "opacity-0"
      }`}
    >
      <div className="flex flex-wrap justify-center items-center h-full">
        <div className="text-center">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p className="w-full text-slate-500">Submitting Order</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;

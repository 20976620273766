import React, { useState } from "react";
import {
  formatMoney,
  formatTimeStampToLocale,
  makeAxiosCall,
} from "../utils/utils.js";
import { CartContext } from "../context/Cart";
import RadioSelector from "./RadioSelector";
import { UserContext } from "../context/User";

interface ShippingRatePayload {
  cartId: number;
  shippingAddress: {
    addressee: string;
    streetAddress: string;
    streetAddressTwo: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
}

const ShippingRates = () => {
  const [shippingRates, setShippingRates] = useState([]);
  const { cart, setCart } = React.useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const {user} = React.useContext(UserContext);

  const fetchShippingRates = () => {
    setIsLoading(true);

    const shipRateObj: ShippingRatePayload = {
      cartId: cart.id,
      shippingAddress: {
        addressee: cart.shippingAddress.addressee,
        streetAddress: cart.shippingAddress.streetAddress,
        streetAddressTwo: cart.shippingAddress.streetAddressTwo,
        city: cart.shippingAddress.city,
        state: cart.shippingAddress.state,
        zip: cart.shippingAddress.zip,
        country: cart.shippingAddress.country,
      },
    };

    makeAxiosCall("post", "/calculate-shipping", shipRateObj)
      .then((res) => {
        setIsLoading(false);
        let rates = res.data;



        if (cart.subtotal > 99 ||(user && user.email)) {
          rates.push({
            name: "Free Shipping",
            amount: 0,
            estimatedArrival: "UPS Ground",
            serviceId: 24,
            carrierId: 7,
          });
        }

        //order by amount ascending
        rates.sort((a, b) => a.amount - b.amount);

        setShippingRates(rates);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleshippingPreferenceSelect = (method) => {
    console.log(method);
    let newCart = { ...cart };
    newCart.shippingPreference = method;
    setCart(newCart);
  };

  React.useEffect(() => {
    fetchShippingRates();
  }, [cart.shippingAddress]);

  return (
    <div className="">
      <h3 className="text-2xl font-bold">Shipping Rates</h3>
      {isLoading ? (
        <div className="mt-4">
          <div
            className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="mt-4">
          <RadioSelector
            callback={handleshippingPreferenceSelect}
            options={shippingRates}
          />
          {/* {renderShippingRates()} */}
        </div>
      )}
    </div>
  );
};

export default ShippingRates;

export const demoOrder = {
  billing_address: {
    addressee: "",
    city: "",
    country: "",
    created_at: "",
    id: null,
    state: "",
    street_address: "",
    street_address_2: "",
    zip: "",
  },
  discount_amount: 0,
  discount_code: "",
  financial_status: "",
  fulfilled_at: null,
  fulfillment_status: "",
  order_notes: null,
  id: null,
  shipping_preference: {
    id: null,
    account_number: null,
    shipping_carrier: { id: 7, name: "UPS" },
    shipping_service: { id: 24, name: "Ground" },
  },
  items: [
    {
      fulfillment_status: "pending",
      id: 3484,
      order_id: 2766,
      price: 1199.99,
      product_name: "Motif™ Metro",
      product_sku: null,
      quantity: 1,
      product: {
        id: 1975,
        image_url:
          "https://firebasestorage.googleapis.com/v0/b/dealerportal-2fb18.appspot.com/o/products%2F1975%2Fimages%2FMotif-Metro---P1---Magenta.png?alt=media",
        name: "Motif™ Metro",
        media: [],
        sku: "124276",
        variant_label: "New",
        website_url: "motif-metro",
        webflow_item_id: "645535ebf740be2130baeecb",
        webflow_collection: {
          domain: "https://www.blizzardpro.com/",
          slug: "products",
          site_name: null,
          name: "Pro Products",
          id: "6454060ee94db518a1803d42",
        },
      },
    },
  ],
  paid_at: "2023-07-18T14:39:12+00:00",
  payment_id: "60223730677",
  payment_method: "Authorize.net",
  payment_references: "60223730677",
  refunded_amount: 0,
  shipping: 0,
  shipping_address: {
    addressee: "tyler hahn",
    city: "Saint Francis",
    company_name: null,
    country: "US",
    created_at: "2023-07-18T14:39:12.947428+00:00",
    id: 5532,
    state: "WI",
    street_address: "3505 E Plainfield Ave",
    street_address_2: "",
    zip: "53235-5545",
  },
  so_number: null,
  subtotal: 1194.99,
  tax_details: [
    { name: "State", value: 60 },
    { name: "County", value: 6 },
    { name: "City", value: 0 },
    { name: "District", value: 0 },
  ],
  taxes: 66,
  total: 1260.99,
  updated_at: "2023-07-18T14:39:12.947428+00:00",
  created_at: "2023-07-18T14:39:12.947428+00:00",
  anonymous_checkout_email: null,
  customer: {
    email: "tyhahn13@gmail.com",
    first_name: "Tyler",
    id: "ozgi7f1DOaORNN5pIIa5DaIyBQF3",
    last_name: "hahn",
    phone: "",
  },
};

import React, { useState } from "react";
import Input from "./Input";
import { AddressInterface, CartInterface } from "../interfaces/Cart";
import { CartContext } from "../context/Cart";
import StateDropdown from "./StateDropdown";
import CountryDropdown from "./CountryDropdown";
import { UserContext } from "../context/User";
import { convertKeysToCamelCase } from "../utils/utils";
import AddressValidation from "./AddressValidation";

interface AddressFormProps {
  keyName: string;
  data: CartInterface;
  title: string;
  isRequired?: boolean;
  validate?: boolean;
  callback?: (e: any) => void;
}

const AddressForm = (props: AddressFormProps) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { title, data, keyName, isRequired, validate } = props;
  const { handleCartChange, setCart, cart } = React.useContext(CartContext);
  const { user } = React.useContext(UserContext);
  const [isBilling, setIsBilling] = useState(false);

  const handleCheckboxChange = () => {
    setIsBilling(!isBilling);

    if (!isBilling) {
      setCart({
        ...cart,
        payment: {
          ...cart.payment,
          billingAddress: cart.shippingAddress,
        },
      });
    }
  };

  const renderCheckbox = () => {
    return (
      <div className="mb-3">
        <input
          onChange={() => handleCheckboxChange()}
          className="mr-2"
          type="checkbox"
          checked={isBilling}
        />
        <span>Billing address is same as shipping address</span>
      </div>
    );
  };

  const renderSuggestedAddresses = () => {
    if (!validate) return;

    return <AddressValidation />;
  };

  const handleSelectAddress = (address) => {
    const findAddress = user.addresses.find((a) => a.id === address);
    let newAddress = convertKeysToCamelCase(findAddress);
    newAddress.country = "US";

    if (findAddress) {
      if (keyName === "billingAddress") {
        setCart({
          ...cart,
          payment: {
            ...cart.payment,
            billingAddress: newAddress,
          },
        });
      } else {
        setCart({
          ...cart,
          [keyName]: newAddress,
        });
      }
    }

    setSelectedAddress(address);
  };

  const renderSelect = () => {
    if (user && user.addresses.length > 0) {
      return (
        <div className="mb-3">
          <label
            htmlFor="Width"
            className="w-full block text-slate-800 pt-2 text-sm mb-2 text-left"
          >
            Saved Addresses
          </label>
          <select
            value={
              selectedAddress ? selectedAddress : "Selected previous address"
            }
            style={{ top: -4, minWidth: 68 }}
            onChange={(e) => handleSelectAddress(parseFloat(e.target.value))}
            className={`relative bg-slate-100 border text-slate-900 text-sm rounded focus:ring-slate-800 border-slate-500 focus:border-slate-500 block w-full p-3`}
          >
            <option value="Selected previous address">
              Select Previous Address
            </option>
            {user.addresses.map((address) => (
              <option key={address.id} value={address.id}>
                {address.street_address}, {address.city}, {address.state},
              </option>
            ))}
          </select>
        </div>
      );
    }
  };
  return (
    <div className="p-6 border rounded border-slate-400 bg-slate-50">
      <h3 className="text-2xl font-bold">{title}</h3>
      {renderSelect()}
      {title === "Billing Address" ? renderCheckbox() : null}

      <Input
        isRequired={isRequired && !data[keyName].addressee}
        value={
          keyName === "billingAddress"
            ? cart.payment.billingAddress.addressee
            : data[keyName].addressee
        }
        className="mr-2"
        onChange={(e) => {
          handleCartChange(keyName, "addressee", e);
        }}
        id="name"
        label="Name"
      />

      <div>
        <Input
          isRequired={isRequired && !data[keyName].streetAddress}
          value={
            keyName === "billingAddress"
              ? cart.payment.billingAddress.streetAddress
              : data[keyName].streetAddress
          }
          onChange={(e) => handleCartChange(keyName, "streetAddress", e)}
          id="address1"
          label="Address"
        />
      </div>
      <div>
        <Input
          value={
            keyName === "billingAddress"
              ? cart.payment.billingAddress.streetAddressTwo
              : data[keyName].streetAddressTwo
          }
          onChange={(e) => handleCartChange(keyName, "streetAddressTwo", e)}
          id="streetAddressTwo"
          label="Apartment, suite, etc. (optional)"
        />
      </div>
      <div className="flex">
        <Input
          isRequired={isRequired && !data[keyName].city}
          className="mr-2"
          value={
            keyName === "billingAddress"
              ? cart.payment.billingAddress.city
              : data[keyName].city
          }
          onChange={(e) => handleCartChange(keyName, "city", e)}
          id="city"
          label="City"
        />

        <StateDropdown
          value={
            keyName === "billingAddress"
              ? cart.payment.billingAddress.state
              : data[keyName].state
          }
          isRequired={isRequired && !data[keyName].state}
          onChange={(e) => handleCartChange(keyName, "state", e.value)}
        />
        <Input
          isRequired={isRequired && !data[keyName].zip}
          value={
            keyName === "billingAddress"
              ? cart.payment.billingAddress.zip
              : data[keyName].zip
          }
          onChange={(e) => handleCartChange(keyName, "zip", e)}
          id="zip"
          className="w-1/3 ml-2"
          label="ZIP Code"
        />
      </div>
      <CountryDropdown
        isRequired={isRequired && !data[keyName].country}
        value={
          keyName === "billingAddress"
            ? cart.payment.billingAddress.country
            : data[keyName].country
        }
        onChange={(e) => handleCartChange(keyName, "country", e.code)}
      />
      {renderSuggestedAddresses()}
    </div>
  );
};

export default AddressForm;

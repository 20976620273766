import React, { useState } from "react";
import { CartContext } from "../context/Cart";

const RadioGroup = ({ options, onChange, selectedAddress }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const { cart } = React.useContext(CartContext);

  const { hasValidatedAddress } = React.useContext(CartContext);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  const renderAddress = (option) => {
    return (
      <div className="flex flex-col">
        <span className="font-bold">{option.addressee}</span>
        <span>{option.street_address}</span>

        <span>
          {option.city}, {option.state} {option.zip}
        </span>
      </div>
    );
  };

  return (
    <div className="mt-6">
      <p
        className={`
          ${
            !hasValidatedAddress ? "text-red-500 bg-red-200 table" : ""
          } font-bold text-lg p-2 rounded mb-3 `}
      >
        Validate Your Address
      </p>
      <div className="flex space-x-4 ">
        {options.map((option) => {
          return (
            <div className="bg-slate-100 p-4 rounded">
              <label key={option} className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-indigo-600 h-5 w-5 mr-2"
                  onChange={() => handleOptionChange(option)}
                />
                <span className="ml-2 text-gray-700">
                  {renderAddress(option)}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioGroup;

import React, { useState } from "react";
import { CartContext } from "../context/Cart";
import { formatMoney } from "../utils/utils.js";

interface CartOverviewProps {
  tabIndex: number;
  setTabIndex: (tabIndex: number) => void;
}

const CartOverview = (props: CartOverviewProps) => {
  const { tabIndex, setTabIndex } = props;

  const { cart } = React.useContext(CartContext);

  console.log(cart);

  const renderCartInfo = () => {
    if (cart.email) {
      return (
        <div
          className={`flex justify-between ${
            cart.shippingAddress.streetAddress
              ? "py-2 mb-2 border-b border-slate-300"
              : null
          }`}
        >
          <div className="flex w-10/12">
            <p className="w-2/12 text-slate-600">Contact</p>
            <p>{cart.email}</p>
          </div>
          <div
            onClick={() => setTabIndex(0)}
            className="cursor-pointer text-primary underline"
          >
            Change
          </div>
        </div>
      );
    }
  };

  const renderShippingAddress = () => {
    if (cart.shippingAddress.streetAddress) {
      return (
        <div
          onClick={() => setTabIndex(0)}
          className={`flex justify-between ${
            cart.shippingAddress.streetAddress
              ? "py-2 mb-2 border-b border-slate-300"
              : null
          }`}
        >
          <div className="flex w-10/12">
            <p className="w-2/12 text-slate-600">Ship To</p>
            <p className="">{`${
              cart.shippingAddress.addressee
                ? cart.shippingAddress.addressee + " ,"
                : ""
            } ${cart.shippingAddress.streetAddress} ${
              cart.shippingAddress.streetAddressTwo
                ? cart.shippingAddress.streetAddressTwo
                : ""
            }, ${cart.shippingAddress.city}, ${cart.shippingAddress.state} ${
              cart.shippingAddress.zip
            }, ${
              cart.shippingAddress.country
                ? cart.shippingAddress.country
                : "United States"
            }`}</p>
          </div>
          <div className="cursor-pointer text-primary underline">Change</div>
        </div>
      );
    }
  };

  const rendershippingPreference = () => {
    if (cart.shippingPreference && cart.shippingPreference.name) {
      return (
        <div
          onClick={() => setTabIndex(1)}
          className={`flex justify-between ${
            cart.shippingAddress.streetAddress ? "py-2" : null
          }`}
        >
          <div className="flex w-10/12">
            <p className="w-2/12 text-slate-600">Shipping</p>
            <p className="">
              {cart.shippingPreference.name} (
              {formatMoney(cart.shippingPreference.amount)})
            </p>
          </div>
          <div className="cursor-pointer text-primary underline">Change</div>
        </div>
      );
    }
  };

  return (
    <div className="border border-slate-300 p-3 rounded text-sm mb-6 bg-slate-50">
      <div>{renderCartInfo()}</div>
      <div>{cart.shipType !== "pickup" && renderShippingAddress()}</div>
      <div>{rendershippingPreference()}</div>
    </div>
  );
};

export default CartOverview;

import React from "react";
import { CartContext } from "../context/Cart";
import { formatMoney } from "../utils/utils.js";
import DiscountInput from "./DiscountInput";

const OrderList = ({ fixed }) => {
  const { order } = React.useContext(CartContext);

  const renderCartItems = () => {
    if (order && order.items.length > 0) {
      return order.items.map((item) => {
        const { product } = item;
        return (
          <div className="_cart-item flex my-8 " key={product.id}>
            <div className="_cart-item-img mr-4" style={{ width: 100 }}>
              <img
                src={product.imageUrl ? product.imageUrl : null}
                alt={product.name}
              />
            </div>
            <div className="_cart-item-info">
              <div className="_cart-item-title text-lg font-bold">
                {product.name}
              </div>
              <div className="_cart-per-price text-sm text-slate-600">
                {formatMoney(item.price)}
              </div>
              <div className="_cart-item-price flex items-center mt-3">
                <input
                  disabled
                  type="text"
                  style={{ width: 50 }}
                  className="bg-slate-200 p-2 rounded"
                  value={item.quantity}
                />
                <div className="_cart-item-price ml-4 text-slate-700">
                  {formatMoney(item.price * item.quantity)}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const renderSalesTax = () => {
    if (order.taxes) {
      return <p className="">{formatMoney(order.taxes)}</p>;
    } else {
      return <p>$-</p>;
    }
  };

  const renderCartInfo = () => {
    return (
      <div className="_cart-subtotal flex justify-between mt-8 border-t border-slate-300 ">
        <div className="text-left w-full pt-4">
          <div className="flex items-center justify-between pb-2">
            <p className="text-lg">Subtotal</p>
            <p className="">{formatMoney(order.subtotal)}</p>
          </div>

          <div className="flex items-center justify-between pb-2">
            <p className="text-lg">Shipping</p>
            <p className=" ml-4">
              {order.shippingPreference ? formatMoney(order.shipping) : "$-"}
            </p>
          </div>

          <div className="flex items-center justify-between pb-4">
            <p className=" text-lg">Sales Tax</p>
            {renderSalesTax()}
          </div>

          <div className="flex items-center justify-between pb-2 pt-4 border-t border-slate-300 w-full">
            <p className=" text-lg font-bold ">Total</p>
            {formatMoney(order.total)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${fixed ? "min-h-screen" : null} p-10  relative w-full`}>
      <div className={`${fixed ? "fixed order-list" : null} `}>
        {renderCartItems()}
        {/* {order && !order.discountCode && <DiscountInput />} */}
        {renderCartInfo()}
      </div>
    </div>
  );
};

export default OrderList;

import React, { useState } from "react";
import { CartInterface, INIT_CART, OrderInterface } from "../interfaces/Cart";
import { convertKeysToCamelCase, makeAxiosCall } from "../utils/utils.js";
import { tax } from "../utils/sales_tax";
import { demoOrder } from "../utils/order_data";
import { UserContext } from "./User";

interface CartContextInterface {
  cart: CartInterface;
  setCart: (cart: CartInterface) => void;
  saveCart: () => void;
  handleSaveDiscountCode: (discountCode: string) => void;
  removeItemFromCart: (id: number) => void;
  adjustCartItemQuantity: (id: number, quantity: string) => void;
  handleCartChange: (name: string, key: string, value: string) => void;
  calculateSalesTax: (price: number, state: string) => void;
  hasValidatedAddress: boolean;
  setHasValidatedAddress: (hasValidatedAddress: boolean) => void;
  order: OrderInterface;
  setOrder: (order: OrderInterface) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  warning: string;
}

export const CartContext = React.createContext<CartContextInterface>(null);

interface CartProviderProps {
  children: any;
}

const CartProvider = ({ children }: CartProviderProps) => {
  const [cart, setCart] = useState<CartInterface>(INIT_CART);
  const [order, setOrder] = useState(convertKeysToCamelCase(demoOrder));
  const [hasValidatedAddress, setHasValidatedAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();

  const { user } = React.useContext(UserContext);

  const handleSaveDiscountCode = (discountCode) => {
    setLoading(true);
    setCart({ ...cart, discountCode: "" });
    const newCart = { ...cart, discountCode: discountCode };

    makeAxiosCall("post", "/cart", newCart)
      .then((res) => {
        setLoading(false);
        const c = convertKeysToCamelCase(res.data);

        setCart({
          ...cart,
          ...c.cart,
        });

        if (c.warnings.length > 0) {
          setWarning(c.warnings[0]);
        } else {
          setWarning(null);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const saveCart = () => {
    makeAxiosCall("post", "/cart", cart)
      .then((res) => {
        const newCart = convertKeysToCamelCase(res.data);

        if (newCart.cart.discountCode === cart.discountCode) return;

        setCart({
          ...cart,
          ...newCart.cart,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCart = () => {
    //get cart id from query string named cartId
    const cartId = new URLSearchParams(window.location.search).get("cartId");

    let userEmail = user && user.email ? user.email : "";

    if (!cartId) {
      makeAxiosCall("get", "/cart")
        .then((res) => {
          const retrievedCart = convertKeysToCamelCase(res.data);

          setCart({ ...cart, ...retrievedCart, email: userEmail });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      makeAxiosCall("get", `/cart/${cartId}`)
        .then((res) => {
          const retrievedCart = convertKeysToCamelCase(res.data);

          setCart({ ...cart, ...retrievedCart, email: userEmail });
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }
  };

  const calculateSalesTax = (price, state) => {
    if (cart.taxExempt) return;

    const nexus = ["CA", "TX", "IL", "WI"];

    if (!nexus.includes(state)) return;

    const selectedstate = tax.find((s) => s.abbreviation === state);

    const taxRate = selectedstate.combinedTaxRate;
    const taxAmount = price * taxRate;

    if (taxAmount !== cart.estimatedTaxes) {
      setCart({ ...cart, estimatedTaxes: taxAmount });
    }
  };

  const adjustCartItemQuantity = (id: number, quantity) => {
    if (quantity > 0) {
      let newCart = cart;
      const item = cart.items.find((item) => item.id === id);

      const newItems = cart.items.map((item) => {
        if (item.id === id) {
          return { ...item, quantity: parseFloat(quantity) };
        } else {
          return item;
        }
      });

      newCart.items = newItems;

      setCart(newCart);
    }
  };

  const removeItemFromCart = (id) => {
    let newCart = cart;
    const newItems = cart.items.filter((item) => item.id !== id);
    newCart.items = newItems;

    setCart(newCart);
  };

  const handleCartChange = (name, key, value) => {
    console.log(name, key, value);
    if (name === "billingAddress") {
      console.log("yo");
      setCart((prevState) => ({
        ...prevState,
        payment: {
          ...prevState.payment,
          billingAddress: {
            ...prevState.payment.billingAddress,
            [key]: value,
          },
        },
      }));
    } else {
      setCart((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          [key]: value,
        },
      }));
    }
  };

  React.useEffect(() => {
    if (!cart.shippingAddress.state) return;
    calculateSalesTax(
      cart.subtotal + cart.shippingPreference.amount,
      cart.shippingAddress.state
    );
  }, [cart.shippingAddress.state, cart.shippingPreference.amount]);

  React.useEffect(() => {
    getCart();
  }, [user]);

  return (
    <CartContext.Provider
      value={{
        setOrder,
        order,
        removeItemFromCart,
        adjustCartItemQuantity,
        cart,
        handleSaveDiscountCode,
        setCart,
        handleCartChange,
        saveCart,
        calculateSalesTax,
        hasValidatedAddress,
        setLoading,
        loading,
        setHasValidatedAddress,
        warning,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;

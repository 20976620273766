import React, { useState } from "react";
import { CartContext } from "../context/Cart";
import Input from "./Input";
import discover from "../images/discover.svg";
import visa from "../images/visa.svg";
import amex from "../images/amex.svg";
import mastercard from "../images/mastercard.svg";
import DiscountInput from "./DiscountInput";
import { formatMoney } from "../utils/utils.js";
import { UserContext } from "../context/User";

const PaymentForm = () => {
  const { cart, setCart, loading, handleCartChange, handleSaveDiscountCode } =
    React.useContext(CartContext);
  const { user } = React.useContext(UserContext);

  const handleUseRewards = () => {
    if (cart.discountCode !== "blizzard_rewards") {
      setCart({ ...cart, discountCode: "blizzard_rewards" });
      handleSaveDiscountCode("blizzard_rewards");
    } else {
      setCart({ ...cart, discountCode: "" });
      handleSaveDiscountCode("");
    }
  };

  const formatCreditCardNumber = (number) => {
    setCart({
      ...cart,
      payment: {
        ...cart.payment,
        card: {
          ...cart.payment.card,
          cardNumber: number,
        },
      },
    });
  };

  const handleExpChange = (e) => {
    let value = e;
    value = value.replace(/(\d{2})(\d{2})/, "$1/$2");
    setCart({
      ...cart,
      payment: {
        ...cart.payment,
        card: {
          ...cart.payment.card,
          expirationDate: value,
        },
      },
    });
  };

  const renderBlizzardRewards = () => {
    if (user) {
      return (
        <div className="w-full pt-6 pb-3">
          <input
            onChange={() => handleUseRewards()}
            className="mr-2"
            type="checkbox"
            disabled={loading || user.blizzard_rewards_balance <= 0}
            checked={cart.discountCode === "blizzard_rewards"}
          />
          <span className="text-md font-bold">
            Use Rewards Balance
            <span className="text-green-600 font-bold">{`(${formatMoney(
              user.blizzard_rewards_balance
            )})`}</span>
          </span>
        </div>
      );
    }
  };
  // const renderCardSelect = () => {
  //   if (user) {
  //     return (
  //       <div className="mb-3">
  //         <label
  //           htmlFor="Width"
  //           className="w-full block text-slate-800 pt-2 text-sm mb-2 text-left"
  //         >
  //           Saved Cards
  //         </label>
  //         <select
  //           value={cart.payment.card.cardNumber}
  //           onChange={(e) => formatCreditCardNumber(e.target.value)}
  //           className="w-full border border-slate-400 rounded p-2 text-sm"
  //         >
  //           <option value="">Select a card</option>
  //           {user.payment_profiles.map((paymentProfile, index) => {
  //             return (
  //               <option key={index} value={paymentProfile.card.cardNumber}>
  //                 {card.cardNumber}
  //               </option>
  //             );
  //           })}
  //         </select>
  //       </div>
  //     );
  //   }
  // };
  return (
    <div className="">
      <div className="p-6 bg-slate-50 border-slate-400 border mb-6">
        <div className="flex justify-between items-center">
          <h3 className="text-2xl font-bold">Payment</h3>

          <div className="flex">
            <img src={discover} alt="" />
            <img src={visa} alt="" />
            <img src={amex} alt="" />
            <img src={mastercard} alt="" />
          </div>
        </div>
        {renderBlizzardRewards()}

        {/* {renderCardSelect()} */}

        <DiscountInput classes="discount-payment" />
        <hr className="border-dashed mt-3 mb-3 border-slate-400" />

        <Input
          label="Card Number"
          type="tel"
          inputMode="numeric"
          pattern="[0-9\s]{13,19}"
          maxLength={19}
          onChange={(e) => formatCreditCardNumber(e)}
          value={cart.payment.card.cardNumber}
          id="nameOnCard"
          placeholder="Enter credit card number"
        />

        {/* <Input
          label="Name on card"
          onChange={(e) => handleCartChange("billingAddress", "addressee", e)}
          value={cart.billingAddress.addressee}
          id="nameOnCard"
          placeholder="Name on card"
        /> */}
        <div className="flex">
          <Input
            className="mr-2"
            label="Exp. Date"
            value={cart.payment.card.expirationDate}
            onChange={(e) => handleExpChange(e)}
            id="nameOnCard"
            placeholder="MM/YY"
            maxLength={7}
          />

          <Input
            label="Security Code"
            maxLength={5}
            onChange={(e) =>
              setCart({
                ...cart,
                payment: {
                  ...cart.payment,
                  card: { ...cart.payment.card, cvv: e },
                },
              })
            }
            value={cart.payment.card.cvv}
            id="nameOnCard"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;

import React, { useState } from "react";
import { CartContext } from "../context/Cart";
import { UserContext } from "../context/User";

interface StateDropdownProps {
  value: string;
  isRequired?: boolean;
  onChange?: (e: any) => void;
}

const exemptTypes = ["Non-Exempt", "Wholesale", "Government", "Other"];

const TaxExemptDropdown = (props: StateDropdownProps) => {
  const { setCart, cart } = React.useContext(CartContext);
  const { user } = React.useContext(UserContext);
  const { onChange, value, isRequired } = props;
  const [selectedExemption, setSelectedExemption] = useState<string | null>(
    null
  );

  return (
    <div className="relative flex ">
      <div>
        <label
          htmlFor="Width"
          className="w-full block text-slate-800 pt-2 text-sm mb-2 text-left"
        >
          Exemption Type
        </label>
        {/* <select
          value={cart.taxExemptType}
          style={{ top: -4, minWidth: 68 }}
          onChange={(e) => setCart({ ...cart, taxExemptType: e.target.value })}
          className={`relative bg-slate-100 border text-slate-900 text-sm rounded focus:ring-slate-800 focus:border-slate-500 block w-full p-3 ${
            isRequired ? "border-red-500" : "border-slate-500"
          }`}
        >
          <i className="fa-solid fa-hexagon-check"></i>
          {exemptTypes.map((state) => (
            <option
              disabled={!user && state !== "Non-Exempt"}
              key={state}
              value={state}
            >
              {state}
            </option>
          ))}
        </select> */}
      </div>
      <div className="ml-4">
        <div className="text-center items-center flex">
          {user && user.tax_exempt && (
            <>
              <label
                htmlFor="Width"
                className="w-full block text-slate-800 pt-2 text-sm mb-2 text-left mr-2"
              >
                Exemption Status
              </label>
              <i className=" mr-2 text-xl fa-solid fa-badge-check text-green-500"></i>
              <p className="text-slate-800 ">Accepted</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaxExemptDropdown;

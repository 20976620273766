import React, { useState } from "react";
import { states } from "../utils/utils.js";

interface StateOption {
  value: string;
  label: string;
}

interface StateDropdownProps {
  value: string;
  isRequired?: boolean;
  onChange?: (e: any) => void;
}

const StateDropdown = (props: StateDropdownProps) => {
  const { onChange, value, isRequired } = props;
  const [selectedState, setSelectedState] = useState<StateOption | null>(null);

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const state = states.find((s) => s.value === value);
    setSelectedState(state || null);
    onChange(state || null);
  };

  return (
    <div className="relative w-1/3">
      <label
        htmlFor="Width"
        className="w-full block text-slate-800 pt-2 text-sm mb-2 text-left"
      >
        State
      </label>
      <select
        value={value}
        style={{ top: -4, minWidth: 68 }}
        onChange={handleStateChange}
        className={`relative bg-slate-100 border text-slate-900 text-sm rounded focus:ring-slate-800 focus:border-slate-500 block w-full p-3 ${
          isRequired ? "border-red-500" : "border-slate-500"
        }`}
      >
        <option value=""></option>
        {states.map((state) => (
          <option key={state.value} value={state.value}>
            {state.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StateDropdown;

import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { formatMoney, formatTimeStampToLocale } from "../utils/utils";

const plans = [
  {
    name: "Startup",
    ram: "12GB",
    cpus: "6 CPUs",
    disk: "160 GB SSD disk",
  },
  {
    name: "Business",
    ram: "16GB",
    cpus: "8 CPUs",
    disk: "512 GB SSD disk",
  },
  {
    name: "Enterprise",
    ram: "32GB",
    cpus: "12 CPUs",
    disk: "1024 GB SSD disk",
  },
];

export default function RadioSelector({ options, callback }) {
  const [selected, setSelected] = useState(plans[0]);

  const handleSelect = (e) => {
    setSelected(e);
    callback(e);
  };

  return (
    <div className="w-full py-4">
      <div className=" w-full max-w-md">
        <RadioGroup value={selected} onChange={(e) => handleSelect(e)}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-y-2">
            {options.map((method) => (
              <RadioGroup.Option
                key={method.name}
                value={method}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-slate-300"
                      : ""
                  }
                  ${checked ? "bg-primary text-white" : "bg-slate-200"}
                    relative flex cursor-pointer rounded-lg px-5 py-4 focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="">
                          <RadioGroup.Label
                            as="p"
                            className={`text-md font-bold  ${
                              checked ? "text-white" : "text-slate-900"
                            }`}
                          >
                            {method.name} - {formatMoney(method.amount)}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline text-sm ${
                              checked ? "text-slate-100" : "text-slate-500"
                            }`}
                          >
                            <span>
                              {formatTimeStampToLocale(method.estimatedArrival)}
                            </span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import React, { useState } from "react";

interface InputProps {
  id: string;
  value: string | number;
  onChange: (value: string) => void;
  className?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  inputMode?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal";
  pattern?: string;
  maxLength?: number;
  isRequired?: boolean;
}

const Input = (props: InputProps) => {
  const {
    id,
    value,
    onChange,
    className,
    label,
    type,
    placeholder,
    inputMode,
    pattern,
    maxLength,
    isRequired,
  } = props;

  const renderInput = () => {
    return (
      <div className="form-group w-full mb-2">
        <input
          inputMode={inputMode ? inputMode : "text"}
          pattern={pattern ? pattern : ""}
          maxLength={maxLength ? maxLength : 100}
          id={id}
          type={type ? type : "text"}
          value={value ? value : ""}
          placeholder={placeholder ? placeholder : ""}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          className={`bg-white border text-slate-900 text-sm rounded focus:ring-slate-800 focus:border-slate-500 block w-full p-3 ${
            isRequired ? "border-red-500" : "border-slate-500"
          }`}
        />
      </div>
    );
  };

  return (
    <div className={`w-full ${className}`}>
      <label
        htmlFor="Width"
        className="w-full block text-slate-800 pt-2 text-sm mb-1 text-left"
      >
        {label}
      </label>
      <div className="flex items-center">{renderInput()}</div>
    </div>
  );
};

export default Input;

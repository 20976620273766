import React, { useState } from "react";
import Input from "./Input";
import { CartContext } from "../context/Cart";
import { convertKeysToCamelCase, makeAxiosCall } from "../utils/utils";

interface DiscountInputProps {
  classes?: string;
}

const DiscountInput = (props: DiscountInputProps) => {
  const { classes } = props;
  const { cart, loading, warning, handleSaveDiscountCode, setCart } =
    React.useContext(CartContext);
  const [discountCode, setDiscountCode] = useState(cart.discountCode);



  return (
    <div>
      <div className={`flex items-center ${classes}`}>
        <Input
          label="Discount Code"
          onChange={(e) => {
            setDiscountCode(e);
          }}
          value={discountCode}
          id="discountCode"
          placeholder="Enter Discount Code"
        />

        <button
          onClick={() => {
            setCart({ ...cart, discountCode: discountCode });
            handleSaveDiscountCode(discountCode);
          }}
          disabled={loading}
          className="mt-6 bg-primary text-white rounded py-3 px-4 ml-2"
        >
          {loading ? "Applying..." : "Apply"}
        </button>
      </div>
      {warning ? (
        <div className="text-red-500 text-sm ml-2 w-full">{warning}</div>
      ) : null}
    </div>
  );
};

export default DiscountInput;
